import React from "react";
import { Section, SubSection } from "~components/text-helpers";
import Latex from "react-latex";
import { graphql, Link } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { css } from "@emotion/react";
import { Table } from "antd";

import {
  Code,
  Response,
  Attribute,
  IL,
  VSpace,
  SideBySideCode,
  Warning,
  APIReferenceiTHOR,
} from "~components/documentation";

export default function iTHORDocReference() {
  return (
    <APIReferenceiTHOR columnKey="concepts" pageName="Concepts">
      <Section sectionTitle="Agent" emoji="robot_face">
        <p>
          An agent is a capsule shaped entity that can navigate within scenes
          and interact with objects.
        </p>
      </Section>
      <Section sectionTitle="Scene" emoji="bed">
        <p>
          A scene within AI2-THOR represents a virtual room that an agent can
          navigate and interact in. There are 4 scene Categories, each with 30
          unique scenes within them: Kitchen, Living Room, Bedroom, Bathroom.
        </p>
      </Section>
      <Section sectionTitle="Action" emoji="joystick">
        <p>
          An action is a discrete command for the agent to perform within a
          scene (e.g. MoveAhead, RotateRight, PickupObject).
        </p>
      </Section>
      <Section sectionTitle="Sim Object" emoji="chair">
        <p>
          A sim object is an object that can be interacted with by the agent.
          Objects have a range of interactions based on their assigned
          <IL>ObjectType</IL>.
        </p>
      </Section>
      <Section sectionTitle="Object Visibility" emoji="bulb">
        <p>
          An object is considered <IL>visible</IL> when it satisfies two
          conditions:
        </p>
        <ol>
          <li>
            <b>In frame</b>. The object appears in the camera's frame (i.e., it
            is within the camera's field of view). Even if an object is
            partially blocked by another object, as long as some of it is in the
            frame, that is sufficient for this condition.
          </li>
          <li>
            <b>Proximity</b>. The object is within a distance of{" "}
            <em>visibilityDistance</em> from the agent's central vertical axis.
            By default, <em>visibilityDistance</em> is set to{" "}
            <Latex>$1.5$</Latex> meters, but it can be overwritten upon{" "}
            <Link to="/ithor/documentation/initialization/#initialization-visibilitydistance">
              initialization
            </Link>
            .
          </li>
          <li>
            <b>Obstruction.</b> The object is not obstructed. Here, a ray
            emitted from the camera must hit the object without first hitting
            another object.
          </li>
        </ol>
        <Warning>
          <Latex>
            An object rendered in an image will not always be visible to the
            agent. For example, an object outside the $1.5$ meter threshold
            could be seen in an image, but will be reported as not-visible to
            the agent.
          </Latex>
        </Warning>
        <p>
          Each object's visibility is reported{" "}
          <Link to="/ithor/documentation/environment-state/#object-metadata-visible">
            in the metadata
          </Link>
          .
        </p>
      </Section>
      <Section sectionTitle="Object Interactability" emoji="bread">
        <p>
          An object is said to be interactable if it is both <IL>visible</IL>{" "}
          and if it is unobstructed by any other objects. For instance, if a{" "}
          <IL>Sponge</IL> is behind <IL>ShowerGlass</IL>, then it will report{" "}
          <IL>visible</IL> as <IL>True</IL>, but the agent will be unable to
          interact with it due to the obstruction.
        </p>
        <Warning>
          If an object is interactable, then that implies the object is visible.
          But, if an object is visible, that does not imply it is interactable.
        </Warning>
      </Section>
      <Section sectionTitle="Receptacle" emoji="takeout_box">
        <p>
          A <IL>Receptacle</IL> is a type of object that may be the parent of
          another object. Examples include <IL>TableTop</IL>, <IL>Cup</IL>,{" "}
          <IL>Sofa</IL>, <IL>Bed</IL>, <IL>Desk</IL>, and <IL>Bowl</IL>. The
          full set of <IL>Receptacle</IL> object types can be filtered on the{" "}
          <Link to="/ithor/documentation/objects/object-types">
            Object Types
          </Link>
          .
        </p>
      </Section>
    </APIReferenceiTHOR>
  );
}
